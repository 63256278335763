
const ProjectItemInfo = (props) => {
    const {title, description, techIconsArr} = props

    const techIcons = techIconsArr.map((img, index) => {
        return <img key={index} src={img} alt={`${title}-icon-${index}`} />;
    })

  return (
    <div className='project-item-info flex-center'>
            <h1>{title}</h1>
            <p>{description}</p>
            <div className="flex-center">
                {techIcons}
            </div>        
      </div>
  )
}

export default ProjectItemInfo
