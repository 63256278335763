import React from 'react'
import BigName from './BigName';

const MainContainer = () => {
  return (
    <div className="top-container">
      <BigName name='Jeremy Krans'/>
    </div>
  )
}

export default MainContainer
