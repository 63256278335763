import React, { useEffect } from 'react';

const BigName = (props) => {

  useEffect(() => {
    const BNContainer = document.querySelector('.bn-container');
    const line = document.querySelectorAll('.bn-line')

    // Set initial width
    BNContainer.style.width = '0rem';

    // Force reflow, making the browser acknowledge the change
    void BNContainer.offsetWidth;

    // Apply transition first
    BNContainer.style.transition = 'width 2s ease-out';
    setTimeout(() => {
        line[0].style.transition = 'transform 2s ease-in, opacity 2s ease';
        line[1].style.transition = 'transform 2s ease-in, opacity 2s ease';

    }, 1500)

    // Use requestAnimationFrame to separate setting of transition and width change
    requestAnimationFrame(() => {
        BNContainer.style.width = '65rem';
        setTimeout(() => {
            line[0].style.transform = 'rotate(-90deg)';
            line[1].style.transform = 'rotate(90deg)';
            line[0].style.transformOrigin = '100% 100%';
            line[1].style.transformOrigin = '0% 100%';
            line[0].style.opacity = '0';
            line[1].style.opacity = '0';
        }, 1500)
    });

  }, []);



  return (
    <div className="bn-container">
        <div className='bn-line'></div>
        <div class="outer-box">
            <div class="inner-box">
                <h1>{props.name}</h1>
            </div>
        </div>
        <div className='bn-line'></div>
    </div>
  );
};

export default BigName;
