import React from 'react'
import Haunted from '../images/hauntedmansionescape.png'
import turkey from '../images/great-turkey-escape.png'
import northpole from '../images/north-pole-escape.png'
import turkeyicon from '../images/turkey.png'
import ToySwapHub from '../images/ToySwapHub.png'
import SantasBag from '../images/SantasBag.png'
import Github from '../images/github.png'
import Pumpkin from '../images/pumpkin.png'
import Robot from '../images/robot.png'
import Gift from '../images/gift.png'
import html from '../images/html-5.png'
import css from '../images/css-3.png'
import javascript from '../images/javascript.png'
import react from '../images/react.png'
import node from '../images/node-js-icon.png'
import csharp from '../images/csharp.png'
import dotnetcore from '../images/dotnetcore.png'
import django from '../images/django.png'
import python from '../images/python.png'
import postgresql from '../images/postgresql.png'
import mssql from '../images/mssql.png'
import bootstrap from '../images/bootstrap.png'
import ProdItemInfo from './ProjectItemInfo'
import ProdItemImg from './ProjectItemImg'
import dalle from '../images/DALL-E.png'


const ProjectItems = () => {
    return (
        <>
            <div className='project-item-container'>
                <ProdItemInfo
                    title='The North Pole Escape'
                    description='A Christmas themed virtual escape geared toward children 10 - 13 years old.'
                    techIconsArr={[javascript, react, node, html, css, postgresql, dalle]}
                />
                <ProdItemImg
                    title1='Github' title2='Website'
                    link1='https://github.com/jkrans/xmasVER/'
                    link2='https://northpoleescape.netlify.app'
                    icon1={Github} icon2={Gift}
                    mainImg={northpole}
                />
            </div>
            <div className='project-item-container'>
                <ProdItemImg
                    title1='Github' title2='Website'
                    link1='https://github.com/jkrans/turkeyVER/'
                    link2='https://greatturkeyescape.netlify.app'
                    icon1={Github} icon2={turkeyicon}
                    mainImg={turkey}
                />
                <ProdItemInfo
                    title='The Great Turkey Escape'
                    description='A Thanksgiving themed virtual escape geared toward children 10 - 13 years old.'
                    techIconsArr={[javascript, react, node, html, css, postgresql]}
                />
            </div>
            <div className='project-item-container'>
                <ProdItemInfo
                    title='The Haunted Mansion Escape'
                    description='A haunted mansion virtual escape geared toward children 10 - 13 years old.'
                    techIconsArr={[javascript, react, html, css,]}
                />
                <ProdItemImg
                    title1='Github' title2='Website'
                    link1='https://github.com/jkrans/hauntedver/'
                    link2='https://hauntedmansionescape.netlify.app'
                    icon1={Github} icon2={Pumpkin}
                    mainImg={Haunted}
                />
            </div>
            <div className='project-item-container'>
                <ProdItemImg
                    title1='Github' title2='Video Demo'
                    link1='https://github.com/jkrans/WebAppToys/'
                    link2='https://www.loom.com/share/fcf3a32689244a1fa13298639c275b8b?sid=882f4e56-b7f0-442d-956c-6b665bec6ae2'
                    icon1={Github} icon2={Robot}
                    mainImg={ToySwapHub}
                />
                <ProdItemInfo
                    title='Toy Swap Hub'
                    description='This project demonstrated a centralized platform where individuals could buy, swap, and sell toys. The links to the next video are in the summary of the first video.'
                    techIconsArr={[csharp, dotnetcore, html, bootstrap, javascript, mssql]}
                />
            </div>
            <div className='project-item-container'>
                <ProdItemInfo
                    title="Santa's Bag"
                    description='A simple way to track Christmas gifts'
                    techIconsArr={[python, django, html, bootstrap, javascript, postgresql]}
                />
                <ProdItemImg
                    title1='Github' title2='Website'
                    link1='https://github.com/jkrans/FinalProject_3620/'
                    link2='https://santasbag.up.railway.app/'
                    icon1={Github} icon2={Gift}
                    mainImg={SantasBag}
                />
            </div>
        </>
    )
}

export default ProjectItems
