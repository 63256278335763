const Footer = (props) => {

    const currentYear = new Date().getFullYear();
  
    return (
      <div className="footer" style={{color: props.color}}>
        <p>Copyright © {currentYear} Jeremy Krans. All Rights Reserved.</p>       
      </div>
    )
  }
  
  export default Footer