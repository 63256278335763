import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import MainContainer from './components/MainContainer'
import Projects from './components/ProjectItems';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <MainContainer />
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
