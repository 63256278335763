import React from 'react'

const ProjectItemImg = (props) => {
    const {title1, title2, link1, link2, icon1, icon2, mainImg} = props
  return (
    <div className='project-item-img-container flex-center'>
        <div className='flex-center project-img-links'>
            <a href={link1} target='_blank' rel="noopener noreferrer" alt='' className='flex-center project-links'>
                <p>{title1}</p>
                <img src = {icon1}  alt=''/>
            </a>
            <a href={link2} target='_blank' rel="noopener noreferrer" className='flex-center project-links'>
                <p>{title2}</p>
                <img src = {icon2}  alt='' />
            </a>
        </div>
        <img src = {mainImg} className='project-item-img border' alt=''/>
      </div>
  )
}

export default ProjectItemImg
